.contact-wrap {
    padding: 1px;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.808);
}
.contact-container {
    display: flex;
}
.contact-sect {
    flex: 1;
    margin-bottom: 3rem;
    margin-right: 4rem;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
}
.ContactItem {
    color: whitesmoke;
    min-width: 300px;
}
.ContactItem:not(:last-child) {
    margin-bottom: 1rem;
}
.contact {
    padding: 1rem;
    display: flex;
    align-items: center;
    height: 10rem;
    background-color: #191d2b;
    border-radius: 10px;
}
.contact > img {
    padding: 1rem;
    border: 2px solid #2e344e;
    border-radius: 10px;
    max-width: 94px;
}
.right-items {
    margin-left: 2rem;
}
.map-sect > iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.map-sect {
    flex: 1;
    margin-top: 3rem;
    margin-left: 4rem;
    margin-right: 2rem;
    margin-bottom: 3rem;
    padding: 1rem;
    background-color: #191d2b;
    border-radius: 10px;
}
.right-items > h6 {
    font-size: 1.4rem;
    color: wheat;
}
.aalu {
    margin-top: 4rem;
    margin-left: 4rem;
}
@media (max-width: 830px) {
    .contact-container {
        flex-direction: column;
    }
    .contact-wrap {
        height: 1250px;
    }
    .contact-sect {
        margin-left: 4rem;
    }
    .map-sect {
        min-height: 400px;
        margin-bottom: 0;
        margin-right: 4rem;
    }
    .map-sect > iframe {
        min-height: 400px;
    }
}
@media (max-width: 500px) {
    .contact > img {
        width: 50px !important;
        height: 50px !important;
        padding: 2px;
    }
    .ContactItem {
        min-width: 200px !important;
    }
    .right-items {
        margin-left: .5rem;
    }
}
@media (max-width: 335px) {
    .contact-sect {
        margin: 0 2rem;
    }
    .map-sect {
        margin: 2rem 2rem;
    }
}
.App {
    width: 100%;
}
@media(max-width: 500px){
    .contact-sect{
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
    .map-sect {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }
}