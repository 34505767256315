/* @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap'); */
:root {
  --project-height: 300vh;
  --primary-white: #fff;
  --primary-orange: #f9ab00;
  /* --primary-dark: rgba(255,255,255,0); */
  --primary-dark: #000;
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
}
p {
  margin-block-start: 0 !important;
  margin-block-end: 0 !important;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box !important;
}
body {
  font-family: 'M PLUS Rounded 1c', sans-serif;
  color: #a4acc4;
  font-size: 1.2rem;
}
body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-thumb {
  background-color: #0432afd5;
  border-radius: 50px;
}
body::-webkit-scrollbar-track {
  background-color: #777;
}

.main-content{ 
  width: 84%;
  margin-left: 16%;
}

/* header ------------------------------*/
.header-wraper {
  position: relative;
  background: url(./img/bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.main-info {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-family: "Roboto Condensed", sans-serif; */
  font-weight: bolder;
  z-index: 1;
}

.main-info h1 {
  color: wheat;
   text-shadow: 2.5px 2.5px rgba(0, 0, 0, 1);
  text-transform: uppercase;
}

/* typed text------------------------------ */
.typed-text {
  font-size: 2rem;
  /* color: var(--primary-white); */
  color: wheat;
   text-shadow: 2.5px 2.5px rgba(0, 0, 0, 1);
  text-transform: capitalize;
  margin: 1rem 0;
}

@media(max-width: 768px) {
  .main-info h1 {
    font-size: 1.3rem !important;
  }
  .typed-text {
    font-size: 1.3rem !important;
  }
}

/* HEADER CONTACT ME BTN-------------- */
.btn-main-offer {
  border: 1px solid;
  border-color: lightslategrey;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 10px 12px 12px 12px;
  color: var(--primary-white);
  margin: 2rem;
  background-color: royalblue;
  font-size: medium;
}
.btn-main-offer:hover {
  color: blue;
  /* text-decoration: none; */
  transition: .2s ease-in-out;
}
/* ------------------------Header social media link----------- */
.icons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-holder:not(:last-child) {
  margin-right: 1rem;
}
.icon {
  font-size: 2rem;
  transition: all .4s ease-in-out;
}
.fb {
  color: blue;
}
.fb:hover {
  color: rgba(0, 0, 255, 0.63);
}
.icons > img {
  height: 1.8rem;
  width: 1.8rem;
  background-color: wheat;
  border-radius: 7px;
  transition: all .2s ease-in-out;
}
.icons > img:hover {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}
.display_viber {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.display_viber img {
  height: 40vh;
  width: 40vh;
}
.no_viber {
  display: none;
}
.cross {
  display: flex;
  flex-direction: column;
}
.cross_img {
  height: 2rem !important;
  width: 2rem !important;
  border-radius: 2rem;
  align-self: flex-end;
  cursor: pointer;
}


/* PARTICLES JS------------------------------- */
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}


/* -----------------sidebar---------------- */
.sidebar {
  width: 16%;
  height: 100vh;
  background-color: #191D28;
  position: fixed;
  z-index: 7;
  border-right: 1px solid #2e344e;
  transition: all .4s ease-in-out;
  transform-origin: left;
}

@media screen and (max-width: 1000px) {
  .sidebar {
    transform: translateX(-100%);
    width: 35%;
  }
  .main-content{
    margin-left: 0;
    width: 100%;
  }
  .nav-toggle {
    transform: translateX(0) !important;
  }
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

}

.profile {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  border-bottom: 1px solid #2e344e;
}
.profile > img {
  width: 70%;
  border-radius: 100%;
  border: 4px solid #2e344e;
  margin-bottom: 1rem;
  max-width: 200px;
  max-height: 200px;
}

.footer {
  width: 100%;
  border-top: 1px solid #2e344e;
  padding: 1rem 0;
  text-align: center;
  color: #a4acc4;
}
.nav-items {
  width: 100%;
  list-style: none;
  text-align: center;
  text-decoration: none;
  font-size: inherit;
}
.nav-item > div > a {
  list-style: none !important;
  display: block;
  padding: .5rem 0;
}
.active, .active:hover {
  background-color: #037fff;
  color: #fff;
}
.alink > a::before {
  content: '';
  position: absolute;
  left: 0;
  /* top: 40%; */
  width: .4rem;
  height: 5%;
  overflow-x: hidden;
  background-color: #0381ff33;
  transform: scale(0);
  transition: transform .4s, .2s width .2s cubic-bezier(1,-0.16,0,1.32); 
}
.alink > a:hover::before {
  width: 100%;
  transform: scale(1);
  overflow-x: hidden;
}

.nav-btn {
  z-index: 10;
  left: .5%;
  top: 1.5%;
  width: 1.8rem;
  height: 1.45rem;
  display: none;
  cursor: pointer;
  position: fixed;
  background-color: black;
}
.lines-1, .lines-2, .lines-3 {
  height: .3rem;
  width: 100%;
  background-color: greenyellow;
  pointer-events: none;
  margin-bottom: .2rem;
  border-radius: 20px;
  display: none;
}
.lines-3 {
  margin-bottom: 0;
}
@media screen and (max-width: 1000px) {
  .nav-btn {
    display: block;
  }
  .lines-1, .lines-2, .lines-3 {
    display: block;
  }
}
@media (max-width: 768px) {
  .icons {
    margin-top: 2rem;
  }
}
