a {
    color: wheat;
}
.Projects {
    padding: 1px;
    height: var(--project-height); 
    background-color: rgba(0, 0, 0, 0.808);
}
.project {
    margin-top: 4rem;
}
.projects-container {
    margin: 4rem;
}
.MenuItem {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
    column-gap: 1rem;
}
.img-data {
    position: relative;
}
.img-data::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    transform-origin: center;
    transform: scale(0);
    opacity: .8;
    transition: all .4s ease-in-out;
}
.img-data:hover::before {
    transform: scale(1);
}
.img-data > img{
    width: 100%;
    height: 50vh;
}
.hover-items {
    list-style: none;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1rem 0;
    visibility: hidden;
}
.img-data:hover > .hover-items {
    visibility: visible;
    transition: all .5s ease-in-out .2s;
    top: 50%;
}
.menu-title > h5 {
    font-size: 1.7rem;
    font-weight: 400;
    color: wheat;
    transition: all .3s ease-in-out;
}
.menus > h5:hover {
    color: #037fff;
    cursor: pointer;
}
.Categories > button {
    margin: 1rem .5rem;
}
.Categories > .btn:active {
    background-color: royalblue;
}
.Categories > .btn:focus {
    background-color: royalblue;
}
.Categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 2rem;
}
.hover-items > li > a > img {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin-right: 1rem;
    cursor: pointer;
}
@media (max-width: 1125px) {
    .img-data > img {
        height: 40vh;
    }
}
@media (max-width: 770px) {
    .img-data > img {
        height: 30vh;
    }
}
@media (max-width: 780px) {
    .Categories {
        grid-template-columns: repeat(2, 1fr);
    }
    .Categories > button {
        margin: .5rem .5rem;
    }
}
@media (max-width: 650px) {
    .MenuItem {
        grid-template-columns: repeat(1, 1fr);
    }
    .img-data > img {
        height: 60vh;
    }
    .Projects {
        height: 500vh;
    }
}
@media (max-width: 575px) {
    .img-data > img {
        height: 50vh;
    }
    .Projects {
        height: 340vh;
    }
}
@media (max-width: 500px) {
    .img-data > img {
        height: 40vh;
    }
    .Projects {
        height: 290vh;
    }
}
@media (max-width: 425px) {
    .img-data > img {
        height: 30vh;
    }
}
@media (max-width: 340px) {
    .img-data > img {
        height: 20vh;
    }
}
@media (max-width: 280px) {
    .img-data > img {
        height: 15vh;
    }
    .Projects {
        height: 200vh;
    }
}
@media (max-width: 460px) {
    .Categories > button {
        margin: .2rem .2rem;
    }
    .Categories {       
        grid-template-columns: repeat(1, 1fr);
    }
    .Projects {
        height: 300vh;
    }
}
.menu-title {
    margin-top: 1rem !important;
    margin-bottom: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.project-btns {
    display: flex;
}
.project-btn {
    margin-right: 1rem;
}
.modal {
    margin-left: 4rem;
}
.project-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.project-details > div > img {
    height: 10rem;
    margin: 1rem;
}
.closee {
    display: flex;
    justify-content: flex-end;
}
.close-model {
    max-width: 2rem;
    z-index: 100;
    border-radius: 100%;
    cursor: pointer;
}
@media (min-width: 650px) {
    .project-details > div > img {
        height: 20rem !important;
    }
}
@media (min-width: 1000px) {
    .project-details {
        margin-left: 16%;
    }
}
.project-details p{
  color: black !important;
}