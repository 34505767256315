.services-container{
    margin: 4rem;
    display: flex;
}
@media (max-width: 760px) {
    .services-container {
        flex-direction: column;
    }
    .serviceSection {
        margin: 1rem 0 !important;
    }
}
.service {
    width: 100%;
    min-width: 185px;
    background-color: #191d28;
    border-left: 1px solid #2e344e;
    border-right: 1px solid #2e344e;
    border-top: 8px solid #2e344e;
    border-radius: 10px;
    transition: all .4s ease-in-out;
    color: wheat;
    min-height: 300px;
}
.service:hover {
    border-top: 8px solid #037fff;
}
.service-content {
    padding: 1rem;
}
.s-title {
    font-size: 1rem;
    font-weight: bolder;
    position: relative;
    padding-bottom: 1rem;
    margin: 1rem 0;
}
.s-title::after {
    content: none;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #2e344e;
    width: 30%;
    height: .4rem;
}
.serviceSection {
    flex: 1;
    margin: 0 10px;
}