.AboutPage {
    height: 2000px;
    background-color: rgba(0, 0, 0, 0.808);
}
.Title {
    text-transform: uppercase;
    font-size: 2rem;
    color: white;
    position: relative;
    padding-bottom: .6rem;
    letter-spacing: 3px;
}
.Title::before {
    content: '';
    bottom: 5px;
    position: absolute;
    left: 0;
    background-color: #0381ff62;
    width: 5rem;
    height: .3rem;
    border-radius: 50px;
}
.Title::after {
    content: '';
    bottom: 5px;
    position: absolute;
    left: 0;
    background-color: #037fff;
    width: 2.5rem;
    height: .3rem;
    border-radius: 50px;
}
.Title > h3 > span {
    position: absolute;
    top: 45%;
    left: 0;
    font-size: 2.5rem;
    opacity: 0.07;
}
.imageSection {
    display: flex !important;
    margin-top: 4rem;
    align-items: center;
    color: white;
    margin-bottom: 4rem;
}
@media  screen and (max-width: 760px) {
    .imageSection {
        flex-direction: column;
    }
}
.img {
    flex: 1;
    display: flex;
    justify-content: center;
}
.img > img {
    max-height: 480px;
}
.about-info {
    flex: 1;
    margin-left: 2rem;
}
.about-info > h4 {
    font-size: 2rem;
}
.about-info > h4 > span {
    color: greenyellow;
    font-size: 3.5rem;
    font-family: 'Aguafina Script', cursive;
}
.about-text {
    padding: 1rem 0;
}
.about-details {
    display: flex;
    margin-top: 1rem;
}
.about-info > p {
    padding: .3rem 0;
}
.right-section {
    margin-left: 2.3rem;
}
.btn {
    padding: .5rem 1rem;
    background-color: #037fff;
    outline: none;
    border: none;
    font-family: inherit;
    font-size: 1.5rem;
    color: wheat;
    text-shadow: 1px 1px rgba(0, 0, 0, 1);
    cursor: pointer;
    letter-spacing: 2px;
    margin-top: 1rem;
    position: relative;
}
.btn::after {
    position: absolute;
    content: '';
    width: 0;
    height: .3rem;
    left: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.808);
    border-radius: inherit;
    transition: all 0.4s ease-in-out;
}
.btn:hover::after {
    width: 100%;
}
@media (max-width: 460px) {
    .AboutPage {
        height: 3500px !important;
    }
}
.achievements-container {
    margin: 0 4rem;
    color: wheat;
}
.achievements-container > ul > li:not(:last-child) {
    margin-bottom: 1rem;
}
.Achieve {
    margin: 0 4rem;
    margin-bottom: 2rem;
}
@media (max-width: 760px) {
    .AboutPage {
        height: 3000px
    }
}